// Header

// Donut filter
.c-donut-filter {
    margin: 0 58px 0 0;
}

.c-donut-filter__item {
    display: flex;
    align-items: center;
    padding: 11px 0;
    color: #727272;

    .label-col {
        flex: 0 0 auto;
        padding: 0 14px 0 0;
        font-size: 14px;
        font-family: 'Muli';
        font-weight: 700;
        line-height: 1;
    }

    .input-col {
        flex: 1 1 auto;
    }
}
.c-donut-filter__item--intervention {
    min-width: 232px;
}

// Legend

// Donut
.c-donut-wrap {
    padding: 38px 0 48px;
}
.c-donut {
    position: relative;
    width: 264px;
    height: 264px;
    margin: 0 auto;

    .ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
        fill: $color-success;
    }

    .ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
        fill: $color-info;
    }

    .ct-label {
        fill: #fff;
        color: #fff;
        font-size: 14px;
        font-family: 'Poppins';
        font-weight: 500;
    }
}

.c-donut__sum {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 21px;
    font-weight: 500;
    color: #727272;
    line-height: 1;
}