// Header

// Legend

// Bar
.c-bars {
    height: 250px;
    margin: 35px 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .o-progress-spinner {
        width: 100%;
    }
}

.c-bars__section {
    flex: 0 0 42px;
    max-width: 42px;
    display: flex;
    align-items: flex-end;
}

.c-bar__line {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-success;
    border-radius: 10px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
}
.c-bar__line--stationary {
    margin-right: 2px;
    background-color: $color-info;
}

// Label
.c-bars-labels {
    display: flex;
    justify-content: space-between;
    margin: 5px 25px 0;
    padding: 12px 0 22px;
    border-top: 1px solid #F1F1F1;
}

.c-bars-labels__item {
    flex: 0 0 42px;
    max-width: 42px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    color: #7d7d7d;
    line-height: 25px;
}