.c-droppable,
.c-droppable-tbody {
    position: relative;

    .submitting {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(256, 256, 256, 0.3);

        .o-progress-spinner {
            height: 100%;
        }
    }
}